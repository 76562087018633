<template>
  <section class="ai-section">
    <div class="ai-section__container container">
      <div class="ai-section__content">
        <h4 class="ai-section__content-title">
          Descubra o valor do seu imóvel agora!
        </h4>
        <p class="ai-section__content-description">
          Conheça nosso avaliador com inteligência artificial que irá te ajudar a encontrar o melhor preço para o seu imóvel!
        </p>
        <button class="ai-section__content-button button-primary" @click="handleLinkRedirect">
          Descobrir agora
        </button>
      </div>
      <div class="ai-section__image">
        <img alt="Mulher pensativa segurando um celular" src="~@/assets/image-lia.png" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AISection',
  methods: {
    handleLinkRedirect () {
      const link = 'https://www.imogo.online/'
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.ai-section {
  padding: 0 0 100px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--green-custom);
    border-radius: 60px;
    gap: 42px;
    padding: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 100px;
    max-width: 580px;

    &-title {
      font-size: 35px;
      color: var(--white);
      font-weight: 600;
      line-height: 40px;
    }

    &-description {
      color: var(--white);
      font-weight: 500;
      margin-top: 16px;
    }

    &-button {
      margin-top: 60px;
      max-width: 160px;
      min-width: unset;
      border: none;
      background: var(--white);
      color: var(--green-custom);
      font-weight: 400;
      border-radius: 10px;
    }
  }

  &__image {
    display: flex;
    position: relative;
    padding-right: 30px;
    max-width: 450px;
    height: 100%;
    flex: 1;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 768px) {
  .ai-section {
    padding: 0 16px 40px 16px;

    &__content {
      padding-left: 0;
    }

    &__container {
      flex-direction: column;
      padding: 42px 15px;
    }

    &__content {
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<template>
  <header>
    <div class="container">
      <div class="logo-header">
         <router-link :to="{ name: 'Home' }"><img alt="Portal imoGo" src="~@/assets/logo.svg"></router-link>
      </div>
      <div class="navigation-header" :class="{ 'is-active': activeMenu }">
        <nav class="nav">
          <router-link :to="{ name: 'About' }" exact><span @click="toggleMenu()" >Sobre a imoGo</span></router-link>
           <router-link :to="{ name: 'PropertiesList'}" exact><span @click="toggleMenu()" >Imóveis</span></router-link>
          <router-link :to="{ name: 'Blog' }" exact><span @click="toggleMenu()" >Blog</span></router-link>
          <a href="#contato" ><span @click="toggleMenu()" >Contato</span></a>
        </nav>
      </div>
      <div class="hamburger hamburger--squeeze" :class="{ 'is-active': activeMenu }" @click="toggleMenu()">
        <div class="hamburger-box">
          <div class="hamburger-inner"></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',

  data () {
    return {
      activeMenu: false
    }
  },

  methods: {
    toggleMenu () {
      this.activeMenu = !this.activeMenu
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    background: var(--white);
    height: 72px;
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    z-index: 20;
    align-items: center;
    border-bottom: solid 1px var(--gainsboro);

    .container {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
    }

    .logo-header {
      display: flex;
      a {
        display: flex;
        cursor: pointer;
      }
      img {
        padding: 8px 0px 8px 0px;
        display: block;
        cursor: pointer;
        width: 109px;
        height: 59px;
      }
    }

    .navigation-header {
      display: flex;
      align-items: center;

      .nav {
        a {
          color: var(--secundary-black);
          font-weight: 500;
          font-size: 14px;
          line-height: 29px;
          font-family: var(--font-primary);
          padding: 0 15px;
          text-decoration: none;
          transition: all 0.20s ease-in-out;

          &:hover,
          &:active,
          &:focus {
            color: var(--green-custom);
            font-weight: 500;
            transition: all 0.20s ease-in-out;
          }
        }
      }

      .search {
        .button-primary {
          min-width: 207px;
          margin: 0 44px 0 64px;
        }
      }

      .social {
        a {
          outline: none;
          cursor: pointer;

          img {
            display: block;
          }
        }
      }
    }

    .hamburger {
      display: none;
    }

    @media (max-width: 768px) {
      height: 60px;
      position: fixed;
      width: 100%;

      .logo-header img { width: 80px; height: auto; }
      .hamburger { display: inline-block; }
      .navigation-header {
        position: fixed;
        height: calc(100% - 60px);
        top: 59px;
        left: -320px;
        width: 300px;
        background: var(--white);
        z-index: 9;
        box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.2);
        padding: 0 0;
        transition: all 200ms ease-out;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .social {
          display: none;
        }

        .nav {
          width: 100%;
          margin-bottom: 12px;
          a {
            display: block;

            span {
              padding: 12px 16px;
              display: block;
            }

            &:hover,
            &:active,
            &:focus,
            &.router-link-active {
              background: var(--green-custom);
              color: var(--white);
            }
          }
        }

        .search {
          width: 100%;
          padding: 0 16px;
          .button-primary {
            margin: 0 0;
          }
        }

        &.is-active {
          left: 0;
          transition: all 200ms ease-out;
        }
      }
    }
  }
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

// const

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/blog',
      name: 'Blog',
      component: () => import('../views/Blog.vue')
    },
    {
      path: '/blog/:slug',
      name: 'BlogPost',
      component: () => import('../views/BlogPost.vue')
    },
    {
      path: '/blog/pesquisar/:search',
      name: 'BlogSearch',
      component: () => import('../views/BlogSearch.vue')
    },
    {
      path: '/sobre-nos',
      name: 'About',
      component: () => import('../views/About.vue')
    },
    {
      path: '/trabalhe-conosco',
      name: 'WorkWithUs',
      component: () => import('../views/WorkWithUs.vue')
    },
    {
      path: '/imoveis',
      name: 'PropertiesList',
      component: () => import('../views/PropertiesList.vue'),
      props: route => ({
        slug: route.query.slug,
        estado: route.query.state,
        cidade: route.query.city,
        vantagens: route.params.benefits,
        tipo: route.params.type,
        valorMinimo: route.params.minValue,
        valorMaximo: route.params.maxValue,
        qtdQuartos: route.params.maxBedroom,
        qtdSuites: route.params.maxSuites,
        qtdGaragens: route.params.maxGarage,
        areaInicial: route.params.minFootage,
        areaFinal: route.params.maxFootage,
        busca: route.params.search
      })
    },
    {
      path: '/imoveis/:id/:slug',
      name: 'Properties',
      component: () => import('../views/Properties.vue')
    },
    {
      path: '/termos',
      name: 'Terms',
      component: () => import('../views/Terms.vue')
    }
  ],

  scrollBehavior () {
    document.getElementById('app').scrollIntoView()
  },

  linkExactActiveClass: 'active'
})

export default router

import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import VueTheMask from 'vue-the-mask'
import VueMeta from 'vue-meta'
import './plugins/axios'

const moment = require('moment')
require('moment/locale/pt-br')

Vue.config.productionTip = false
Vue.use(VueTheMask)
Vue.use(VueMeta)
Vue.use(require('vue-moment'), {
  moment
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <section class="properties-section">
    <div class="container">
      <h2>Publicados recentemente</h2>
      <router-link :to="{ name: 'PropertiesList' }" class="view-more" exact>Ver todos <img alt="Icon arrow left" src="~@/assets/icons/arrow-left.svg"></router-link>
    </div>
    <div class="container container-properties">
      <div
        class="box-properties"
        v-for="item in this.properties"
        :value="item.id"
        :key="item.id"
      >
        <router-link :to="{ name: 'Properties', params: { slug: item.attributes.slug, id: item.id } }" target="_blank" exact>
          <img class="properties-image" alt="" :src="getPhoto(item)">
          <div class="properties-content">
            <p class="tag"></p>
            <div>

              <router-link :to="{ name: 'Properties', params: { slug: item.attributes.slug, id: item.id } }" exact>
                <h3>{{ item.attributes.name }}</h3>
              </router-link>

              <router-link :to="{ name: 'Properties', params: { slug: item.attributes.slug, id: item.id } }" exact>
                <p class="locale">{{ item.attributes.address }}</p>
              </router-link>

              <p
                class="infos"
                v-for="features in item.attributes.features.slice(0,2)"
                :value="features.id"
                :key="features.id"
              >
                {{ features.name }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { propertiesService } from '@/services/properties.service'

export default {
  name: 'PropertiesSection',
  data () {
    return {
      properties: []
    }
  },

  mounted () {
    this.getProperties()
  },

  methods: {
    async getProperties () {
      try {
        const pagination = {
          perPage: 4,
          page: 1
        }
        const properties = await propertiesService.listProperties({ is_visible: true }, pagination)
        this.properties = properties.data.data.slice(0, 4)
      } catch (error) {
        console.log('Ocorreu um erro inesperado.')
      }
    },

    getPhoto (property) {
      if (property?.attributes?.building_images.length > 0) {
        const index = property?.attributes?.building_images.length - 1
        return property?.attributes?.building_images[index]
      }

      if (property?.attributes?.images != null) {
        return property?.attributes?.images[0]
      }

      return null
    },

    getStatus (key) {
      var status = '-'
      switch (key) {
        case 'under_construction':
          status = 'Em construção'
          break
        case 'release':
          status = 'Lançamento'
          break
        case 'ready_live':
          status = 'Pronto para morar'
          break
        case 'ready_build':
          status = 'Pronto para construir'
          break
        case 'pre_release':
          status = 'Pré Lançamento'
          break
        case 'done':
          status = 'Pronto'
          break
        case 'sold':
          status = '100% vendido'
          break
      }
      return status
    }

  }
}
</script>

<style lang="scss" scoped>
  .properties-section {
    padding: 85px 0 170px;

    .container {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      align-items: center;

      &.container-properties {
        max-width: 1200px;
        margin-top: 28px;
      }
    }

    a {
      text-decoration: none;
      width: 100%;
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
      color: #2F2F33;
      font-weight: 300;
    }

    .view-more {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--maya-blue);
      font-weight: 500;
      font-size: 12px;
      gap: 4px;
      text-decoration: none;
      border: solid 1px var(--gainsboro);
      width: 112px;
      height: 37px;
      border-radius: 12px;
    }

    .box-properties {
      position: relative;
      min-height: 371px;
      display: flex;
      max-width: 280px;
      width: 100%;

      .properties-image {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 25px;
        height: 371px;
        width: 100%;
        object-fit: cover;
      }

      .properties-content {
        position: relative;
        z-index: 5;
        display: flex;
        height: 371px;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px 24px;
        border-radius: 25px;
        background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

        .tag {
          background: var(--orange-custom);
          color: var(--gainsboro);
          font-size: var(--size-small);
          display: flex;
          align-items: center;
          height: 33px;
          max-width: 130px;
          padding: 0 0;
          border-radius: 10px;
          justify-content: center;
          opacity: 0.0;
        }

        a {
          text-decoration: none;
        }

        .logo {
          width: 66px;
          height: auto;
          margin-bottom: 12px;
        }

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: var(--white);
          margin-bottom: 4px;
        }

        .locale {
          font-weight: 300;
          color: var(--white);
          line-height: 19px;
          font-size: var(--size-button);
          margin-bottom: 4px;
        }

        .infos {
          font-weight: 400;
          color: var(--white);
          line-height: 14px;
          font-size: var(--size-small);
          text-transform: uppercase;
          display: inline-block;
          margin-right: 16px;
          position: relative;
          font-weight: 300;
          text-transform: uppercase;

          &::after {
            content: '';
            position: absolute;
            right: -10px;
            top: 4px;
            background: var(--white);
            width: 5px;
            height: 5px;
            border-radius: 100px;
          }
          &:last-child {
            margin-right: 0px;

            &::after {
              display: none;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      padding: 235px 0 45px;

      .container {
        align-items: flex-start;
        flex-direction: column;

        &.container-properties {
          flex: 1;
          flex-wrap: wrap;
          align-items: center;
          flex-direction: row;
          margin-top: 32px;
        }
      }

          .box-properties {
            min-height: inherit;
            margin-bottom: 20px;
            .properties-image {
              width: 100%;
            }
          }

    }

    @media (max-width: 350px) {
      .container.container-properties {
        .box-properties {
          .properties-content {
            height: 345px;
          }
          .properties-image {
            height: 345px;
          }
        }
      }
    }
  }
</style>

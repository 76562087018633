<template>
  <section class="about-section">
    <div class="container">
      <div class="about-image">
        <img alt="" src="~@/assets/image-home-about.png">
      </div>
      <div class="about-content">
        <h4 class="title"> Sobre </h4>
        <h2>Descomplica, vem de imoGo</h2>
        <p>A imoGo é uma startup de soluções imobiliárias inteligentes. Nossa história começa unindo a expertise de grandes nomes do setor imobiliário à necessidade de incorporar tecnologia, praticidade e rapidez aos processos de compra e venda de imóveis. Em pleno 2023, ninguém aguenta lidar com tanta demora e burocracia para tratar com vendas e papeladas.Nascemos para resolver de uma vez por todas estas dores. </p>
        <router-link :to="{ name: 'About' }">
          Ler mais
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutSection'
}
</script>

<style lang="scss" scoped>
  .about-section {
    padding: 0 0 100px;

    .container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 90px;
    }

    .about-content {
      max-width: 530px;
      margin-left: 0;

      .title {
        font-size: 12px;
        font-weight: 700;
        color: var(--orange-custom);
        margin-bottom: 30px;
      }

      h2 {
        color: #2F2F33;
        font-weight: 300;
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 24px;
        max-width: 412px;
      }

      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;

        span {
          font-weight: 500;
        }
      }

      a {
        text-decoration: none;
        color: var(--green-custom);
        font-weight: 700;
        animation: all 200ms ease-in-out;
        font-weight: 700;
        font-size: 16px;
        line-height: 48px;

        &:hover,
        &:focus,
        &:active {
          color: var(--maya-blue);
          animation: all 200ms ease-in-out;
        }
      }
    }

    .about-image {
      position: relative;
      max-width: 629px;

      img {
        max-width: 629px;
        width: 100%;
        height: auto;
      }
    }

    @media (max-width: 768px) {
      padding: 0 0 40px;

      .container {
        flex-direction: column;
      }

      .about-content {
        margin-left: 0;
        margin-bottom: 32px;

        h2 {
          margin-bottom: 32px;
        }
      }
    }

  }
</style>

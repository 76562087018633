<template>
    <div class="modal-backdrop">
      <div class="modal-backoffice">
        <div class="modal-body">
          <slot name="body" />
        </div>
        <slot name="footer" />
      </div>
    </div>
</template>

<script>
export default {
  name: 'Modal',

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

main {

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 99999;
    margin-bottom: 50px;

    .modal-backoffice {
      background: var(--green-custom);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      max-width: 80%;
      border-radius: 10px;
      width: 100%;
      min-height: 166px;
      max-height: 90%;
      height: auto;
      text-align: left;
      position: relative;
      color: #ffffff;

      .modal-body {
        position: relative;
        padding: 40px 40px 40px;

        h2 {
            font-size: 14px;
            font-weight: 700;
        }

        p {
            font-size: 12px;
            font-weight: 400;
            padding-right: 20px;
        }

        a {
            font-size: 12px;
            font-weight: bold;
            text-decoration: none;
            color: #FFFFFF;
            display: inline-block;
            cursor: pointer;

            &:hover,
            &:active,
            &:focus {
                transition: color 0.20s ease-in-out;
                color: #FFFFFF;
            }
        }

        .confirm-button {
            display: flex;
            text-decoration: none;
            background-color: #FFFFFF;
            font-size: 16px;
            font-weight: 700;
            color: var(--green-custom);
            border-radius: 16px;
            align-items: center;
            justify-content: center;
            max-width: 122px;
            width: 100%;
            height: 50px;
            text-align: center;
            line-height: 25px;
            cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .modal-backdrop {
      .modal-backoffice {
        padding: 0;
        margin: 0px 15px;

        .modal-body {
          padding: 30px 15px 40px;
        }
      }
    }
  }
}
</style>

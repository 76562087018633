<template>
  <div id="app">
    <Header />
    <main>
      <router-view/>
      <!-- <a href="https://wa.me/5561983550036?text=Olá%20imoGo,%20Gostaria%20da%20ajuda%20de%20vocês" style="position:fixed;width:60px;height:60px;bottom:98px;right:40px;background-color:#E69138;color:#FFF;border-radius:50px;text-align:center;font-size:30px;box-shadow: 1px 1px 2px #888; z-index:1000;" target="_blank">
        <i style="margin-top:16px" class="fa fa-whatsapp"></i>
      </a> -->
      <div class="float-button" v-on:click="buttonTapped()">
        <img src="~@/assets/fale_conosco.png" alt="download" />
      </div>
      <div class="download" v-if="isDownloadButtonAvailable">
        <a href="https://apps.apple.com/br/app/imogo/id1661571220" alt="ios" target="_blank">
          <div v-on:click="buttonTapped()">
            <i class="material-icons">phone_iphone</i>
          </div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=br.com.politiz.qi&pcampaignid=web_share" alt="android" target="_blank">
          <div v-on:click="buttonTapped()">
            <i class="material-icons">android</i>
          </div>
        </a>
      </div>
      <modal @close="showModal == false" v-show="showModal">
        <template slot="body">
          <h2> Preocupamo-nos com a sua privacidade. </h2>
          <div class="modal-policy">
            <p> Nosso site, assim como links ou sistemas de parceiros integrados em nosso site, podem coletar
              informações pessoais ou não-pessoais para auxiliar na navegação, possibilitar sua identificação,
               e/ou gerar estatísticas de uso. Nossa
               <a href="https://sites.google.com/view/imobdebolso-termos/home?authuser=2" target="_blank"> Política de Privacidade </a>
               apresenta com mais detalhes quais informações são coletadas por nós, além de apresentar links para
               a política de privacidade de parceiros, transparecendo quais informações os mesmos coletam.
               Ao continuar navegando, você concorda com essas condições.
            </p>
            <a class="confirm-button" @click="confirmPolicy()">
              OK
            </a>
          </div>
        </template>
      </modal>
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Modal from '@/components/home/Modal.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Footer,
    Header,
    Modal
  },

  data () {
    return {
      showModal: {},
      isDownloadButtonAvailable: false
    }
  },

  mounted () {
    this.showModal = !this.isAcceptPrivacyPolicy
  },

  computed: {
    ...mapGetters('session', ['isAcceptPrivacyPolicy'])

  },

  methods: {
    ...mapActions('session', ['acceptPrivacyPolicy']),

    confirmPolicy () {
      this.showModal = false
      this.acceptPrivacyPolicy()
    },

    buttonTapped () {
      window.open('https://api.whatsapp.com/send?phone=5561983550036', '_blank')
    },

    download (platform) {
      if (platform === 'ios') {
        window.open('https://apps.apple.com/br/app/imogo/id1661571220', '_blank')
      } else {
        window.open('https://play.google.com/store/apps/details?id=br.com.politiz.qi&pcampaignid=web_share', '_blank')
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/styleguide";
@import "@/assets/css/buttons";
@import "@/assets/css/base";

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  margin: 0px;
  overflow-x: hidden;
}

#app {
  background: var(--white);
  font-family: var(--font-primary);
  font-weight: 300;
  font-size: var(--size-large);
  line-height: 29px;
  color: var(--atomic);
}

.float-button {
  position: fixed;
  bottom: 20px !important;
  right: 20px !important;
  z-index: 1000 !important;
  cursor: pointer;

  img {
    width: 240px !important;
  }

  @media (max-width: 500px) {
    bottom: 0px !important;
    right: 0px !important;

    img {
      width: 165px !important;
    }
  }
}

.download {
  position: fixed !important;
  bottom: 34px !important;
  right: 230px !important;
  z-index: 1000 !important;

  a {
    text-decoration: none;
  }

  div {
    display: flex !important;
    width: 60px !important;
    height: 60px !important;
    border-radius: 30px !important;
    background-color: var(--green-custom) !important;
    color: var(--white) !important;
    margin-top: 20px;
    align-items: center;

    .material-icons {
      margin: 0 auto;
    }
  }
}

.modal-policy {
  display: flex;
}

.post-content {
  margin: 0 0 24px;
  padding: 0 0 0 105px;
  max-width: 1000px;

  p {
    font-weight: 300;
    line-height: 29px;
    margin: 0 0 24px;
    font-size: var(--size-large);
    color: var(--atomic);
    a {
      text-decoration: none;
      color: var(--green-custom);
      font-weight: 700;
    }
  }

  h1 {
    font-size: 32px;
    margin: 0 0 24px;
  }

  h2 {
    margin: 24px 0 24px;
    padding: 0 0 0 0;
    line-height: 36px;
    font-weight: 700;
    font-size: var(--size-subtitle-post);
    color: var(--secundary-black);
  }

  h3 {
    margin-bottom: 16px;
    font-weight: 700;
    margin: 0 0 24px;
    line-height: 29px;
    font-size: var(--size-large);
    color: var(--atomic);
  }

  p,
  ul,
  li,
  ol {
    font-size: var(--size-large);
    color: var(--atomic);
  }

  ul {
    margin-left: 20px;
    list-style-position: outside;
    list-style: disc;
  }

  ol {
    margin-left: 20px;
    list-style-position: outside;
    list-style: decimal;
  }

  blockquote {
      border-left: 3px solid rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.8);
      padding-left: 0.8rem;
      font-style: italic;
    }
  }

  @media screen and (max-width: 768px) {
    .modal-policy {
      display: block;
    }
  }

</style>

import Vue from 'vue'

export const blogService = {
  listPosts,
  getPost,
  searchPosts
}

function listPosts (pagination) {
  return Vue.axios.get(`v2/public/posts?items=${pagination.perPage}&page=${pagination.page}`)
}

function searchPosts (params) {
  return Vue.axios.get(`v2/public/posts?search=${params}`)
}

function getPost (slug) {
  return Vue.axios.get(`/v2/public/posts/${slug}`)
}

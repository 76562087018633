<template>
  <section class="hero">
    <div class="container">
      <div class="content">
        <div class="title">
          <h1>O imóvel que você procura <span>está aqui!</span></h1>
          <ul>
            <li>Preço Justo</li>
            <li>Anúncios únicos e válidos</li>
            <li>Rápido agendamento de visita</li>
          </ul>
        </div>
        <h3>Aonde gostaria de morar?</h3>
      </div>
      <form class="hero-search">
        <div class="box-search">
          <p class="title-select">Local</p>
          <select class="select" v-model="stateSelected" v-on:change="onChange">
            <option v-for="item in this.states" :value="item" :key="item.id">{{ item.attributes.name }}</option>
          </select>
          <p v-if="stateSelected.attributes">{{ stateSelected.attributes.name }}</p>
          <p v-else> Todos </p>
        </div>
        <div class="box-search">
          <p class="title-select">Cidade</p>
          <select class="select" v-model="citySelected">
            <option v-for="item in this.cities" :value="item" :key="item.id">{{ item.attributes.name }}</option>
          </select>
          <p v-if="citySelected.attributes">{{ citySelected.attributes.name }}</p>
          <p v-else> Todos </p>
        </div>
        <router-link class="button-primary" :to="{ name: 'PropertiesList', query: { slug: `${stateSelected?.attributes?.name || ''}_${citySelected?.attributes?.name || ''}`, estado: stateSelected?.id, cidade: citySelected?.id } }">
          Buscar imóvel
        </router-link>
      </form>
    </div>
  </section>
</template>

<script>
import { regionsService } from '@/services/regions.service'

export default {
  name: 'Hero',
  data () {
    return {
      states: [],
      cities: [],
      stateSelected: {},
      citySelected: {},
      all: {}
    }
  },

  mounted () {
    this.all = { id: '', attributes: { name: 'Todos' } }
    this.states = [this.all]
    this.cities = [this.all]
    this.stateSelected = this.states[0]
    this.citySelected = this.cities[0]

    this.getStates()
  },

  methods: {
    async getStates () {
      try {
        const response = await regionsService.retrieveStates()
        this.states = [].concat.apply(this.states, response.data.data)
      } catch {
        console.log('Ocorreu um erro inesperado.')
      }
    },

    async getCities (id) {
      try {
        const params = { state_id: id }
        const response = await regionsService.retrieveRegions(params)
        this.cities = [].concat.apply([this.all], response.data.data)
      } catch {
        console.log('Ocorreu um erro inesperado.')
      }
    },

    onChange: function () {
      const id = this.stateSelected.id
      this.citySelected = this.cities[0]
      this.getCities(id)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/css/select";

  .hero {
    background: url("~@/assets/hero-banner.png") right bottom no-repeat;
    background-size: 50% 100%;
    min-height: 600px;
    display: flex;
    margin: 0 auto;

    .container {
      display: flex;
      height: 100%;
      flex-direction: column;
      max-width: 1200px;
      position: relative;

      .content {
        width: 50%;

        .title {
          padding: 120px 0 80px;

          ul {
            padding: 40px 40px;
            font-size: var(--size-regular);
            font-weight: 500;
            list-style: none;
          }

          li:before {
            content: "→";
            padding-right: 5px;
            color: var(--orange-custom);
          }
        }
      }

      h1 {
        max-width: 560px;
        color: var(--third-black);
        line-height: 64px;
        font-size: var(--size-h1);
        font-weight: 700;
        font-family: var(--font-primary);

        span {
          font-size: var(--size-h1);
          font-weight: 700;
          border-bottom: 14px var(--green-custom) solid;
        }
      }

      h3 {
        font-weight: 300;
        font-size: 18px;
        line-height: 32px;
        color: var(--third-black);
        margin-bottom: 20px;
      }

      .hero-search {
        background: var(--white);
        border: 1px solid #DDDDDD;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
        border-radius: 15px;
        width: 100%;
        padding: 20px 32px;
        height: 104px;
        max-width: 640px;
        margin: 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .box-search{
          margin-right: 16px;
          position: relative;
          width: 200px;

          &:nth-child(1) {
            border-right: 1px solid #EFEFEF;
          }

          .select {
            position: absolute;
            top: 0px;
            max-width: 170px;
            width: 100%;
            color: rgba(0,0,0,0);
          }

        }
        a.button-primary {
            min-width: initial;
            border-radius: 10px;
            width: 139px;
            height: 48px;
            padding: 0 0;
            display: flex;
            align-items: center;
            font-weight: 300;
            font-size: 15px;
            line-height: 24px;
            justify-content: center;
          }
        p.title-select {
          font-size: 16px;
          line-height: 22px;
          font-weight: 300;
          color: #222222;
          margin-top: 0px;
        }

        p {
          font-size: 18px;
          line-height: 22px;
          font-family: var(--font-primary);
          font-weight: 300;
          margin-top: 3px;
          color: #757474;
        }
      }
    }

    @media (max-width: 768px) {
      min-height: initial;
      height: calc(100vh - 210px);
      background-position: bottom center;
      background-size: cover;
      background: linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .4)), url("~@/assets/hero-banner.png") right bottom no-repeat;

      .container {
        flex-direction: column;
        align-items: center;

        .content {
          width: 100%;

          .title {
            padding: 80px 0 134px;

            ul {
              padding: 20px 20px;
              color: var(--white);
              text-shadow: 2px 2px 20px #2d2828;
            }
          }
        }

        h1 {
          padding: 0 0 20px;
          margin: 38% 0 0;
          font-size: 36px;
          max-width: 350px;
          line-height: 44px;
          color: var(--white);
          text-shadow: 2px 2px 20px #2d2828;

          span {
            font-size: 36px;
            color: var(--white);
            text-shadow: 2px 2px 20px #2d2828;
          }
        }

        h3 {
          color: var(--white);
          text-shadow: 2px 2px 20px #2d2828;
        }

        .hero-search {
          bottom: -170px;
          flex-direction: column;
          left: 0;
          position: absolute;
          padding: 16px;
          height: 260px;
          background: var(--white);
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

          .box-search {
            border: none !important;
            width: 100%;
            padding: 0;
            margin: 0 0 8px;

            p {
              font-size: var(--size-regular);
              margin-top: 4px;
            }
            .select {
              max-width: 100%;
              font-size: calc(var(--size-large) + 4px);
            }
          }

          a.button-primary {
            width: 100%;
            margin-top: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    .hero {
      height: 630px;
    }

    .hero .container .hero-search {
      bottom: -160px;
    }

    .hero .container .content .title {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      ul {
        padding: 0;
        margin-bottom: 8px;
      }
    }
  }
</style>

import Vue from 'vue'

export const regionsService = {
  retrieveStates,
  retrieveRegions
}

function retrieveStates () {
  return Vue.axios.get('v2/public/states')
}

function retrieveRegions (params) {
  return Vue.axios.get('v2/public/regions', { params })
}

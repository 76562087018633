<template>
  <div class="home">
    <hero />
    <properties-section />
    <about-section />
    <ai-section />
    <news />
  </div>
</template>

<script>
import Hero from '@/components/home/Hero.vue'
import PropertiesSection from '@/components/home/PropertiesSection.vue'
import AboutSection from '@/components/home/AboutSection.vue'
import News from '@/components/home/News.vue'
import AiSection from '@/components/home/AiSection.vue'

export default {
  name: 'Home',
  metaInfo: {
    title: 'imoGo - Início'
  },
  components: {
    Hero,
    PropertiesSection,
    AboutSection,
    News,
    AiSection
  }
}
</script>

<style lang="scss" scoped>

</style>

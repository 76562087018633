<template>
  <footer id="contato">
    <div class="container">
      <div class="menus-footer">
        <div class="menu-footer">
          <h2>Sobre</h2>
          <router-link :to="{ name: 'Home' }"><span @click="toggleMenu()" >Home</span></router-link>
          <router-link :to="{ name: 'About' }" exact><span @click="toggleMenu()" >Sobre</span></router-link>
          <router-link :to="{ name: 'Blog' }" exact><span @click="toggleMenu()" >Blog</span></router-link>
        </div>
        <div class="menu-footer">
          <h2>Imóveis</h2>
          <router-link :to="{ name: 'PropertiesList' }" exact><span @click="toggleMenu()" >Imóveis</span></router-link>
        </div>
        <div class="menu-footer">
          <h2>Escritório</h2>
          <a href="https://maps.app.goo.gl/5oY8mp8DvA9R7T7g6" target="_blank">Brasília <img alt="Icon arrow left" src="~@/assets/icons/arrow-link.svg"></a>
        </div>
        <div class="menu-footer">
          <h2>Fale conosco</h2>
          <a href="mailto:contato@imogo.com.br">contato@imogo.com.br</a>
        </div>
      </div>
      <div class="logo-footer">
        <img alt="Portal imoGo" src="~@/assets/logo-footer.svg">
        <div class="social">
          <a href="https://www.instagram.com/imogoapp/" target="_blank">
            <img alt="Acesse o Instagram" src="~@/assets/icons/instagram.svg">
          </a>
          <a href="https://www.facebook.com/profile.php?id=100091490825664&mibextid=b06tZ0" target="_blank">
            <img alt="Acesse o Facebook" src="~@/assets/icons/FacebookLogo.svg">
          </a>
          <a href="https://www.linkedin.com/company/90368105/admin/feed/posts/?feedType=following" target="_blank">
            <img alt="Acesse o Linkedin" src="~@/assets/icons/LinkedinLogo.svg">
          </a>
          <a href="https://www.youtube.com/@imoGoapp" target="_blank">
            <img alt="Acesse o Youtube" src="~@/assets/icons/YoutubeLogo.svg">
          </a>
        </div>
      </div>
      <div class="content-footer">
        <p> SCN Quadra 05 Bl A - Brasília Shopping - Torre Norte - Asa Norte, Brasília - DF 70715-900 </p>
        <p> Site de propriedade da imoGo. Todos os direitos são reservados. Ao navegar por esse site, você concorda com o nosso Aviso de Privacidade e <a href="https://sites.google.com/view/imobdebolso-termos/home?authuser=2" target="_blank">Política de Privacidade</a></p>
        <p> IMOGO IMOBILIARIA, PUBLICICIDADE, REPRESENTACAO E TECNOLOGIA DA INFORMACAO LTDA. CNPJ: 50.509.002/0001-89. CRECI: 30152.</p>
        <p> Powered: Imob de Bolso.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>
  footer {
    background: #E5E9EB;
    margin: 0 0;
    padding: 60px 0 60px;

    .container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }

    .menus-footer {
      display: flex;
      width: 100%;
      margin: 0 0 50px;
      justify-content: space-between;

      h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        color: var(--green-custom);
        padding: 0 0;
        margin: 0 0 40px;
      }

      .menu-footer {
        display: flex;
        flex-direction: column;

        a {
          text-decoration: none;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          margin: 0 0 16px;
          padding: 0;
          color: var(--maya-blue);
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover,
          &:focus,
          &:active {
            color: var(--green-custom);
            animation: all 200ms ease-in-out;
          }
        }
      }
    }

    .logo-footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 26px 0;
      border-bottom: solid 1px var(--white);

      > img {
        display: block;
        width: 190px;
        height: 29px;
      }

      .social {
        width: 180px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .content-footer {
      display: flex;
      align-items: center;
      padding: 60px 0 0;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      p {
        font-weight: 300;
        font-size: 14px;
        line-height: 100%;
        text-align: center;
      }

      a {
        text-decoration: underline;
        font-size: 14px;
        line-height: 100%;
        font-weight: 300;
        color: var(--atomic);
        transition: color 0.20s ease-in-out;

        &:hover,
        &:active,
        &:focus {
          transition: color 0.20s ease-in-out;
          color: var(--green-custom);
        }
      }
    }

    @media (max-width: 768px) {
      margin: 0 0;
      padding: 32px 16px;

      .container {
        flex-direction: column;
      }

      .menus-footer {
        flex-direction: column;

        .menu-footer {
          margin-bottom: 16px;

          h2 {
            font-size: 20px;
            margin-bottom: 20px;
          }

          a {
            font-size: 16px;
          }
        }
      }

      .logo-footer {
        margin-bottom: 12px;
        > img {
          width: 155px;
        }
      }

      .content-footer {
        flex-direction: column;
        align-items: flex-start;
      }

    }
  }
</style>

import Vue from 'vue'

export const propertiesService = {
  listProperties,
  getProperties,
  getBenefits
}

function listProperties (params, pagination) {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value !== undefined).map(([key, value]) => [key, value === null ? '' : value])
  )
  const searchParams = new URLSearchParams()
  Object.keys(filteredParams).forEach((key) => {
    const value = filteredParams[key]
    if (Array.isArray(value)) {
      value.forEach((item) => {
        searchParams.append(`${key}[]`, item)
      })
    } else {
      searchParams.set(key, value)
    }
  })

  return Vue.axios.get(`v2/public/properties?${searchParams.toString()}&items=${pagination.perPage}&page=${pagination.page}`)
}

function getProperties (id) {
  return Vue.axios.get(`v2/public/properties/${id}`)
}

function getBenefits () {
  return Vue.axios.get('v2/public/features')
}

<template>
  <section class="news" v-if="this.posts.count != 0">
    <div class="container">
      <h2>Últimas Notícias</h2>
      <router-link :to="{ name: 'Blog' }" class="view-more" exact>Ver todas <img alt="Icon arrow left" src="~@/assets/icons/arrow-left.svg"></router-link>
    </div>
    <div class="container container-news">
      <div
        class="box-news"
        v-for="item in this.posts"
        :value="item.id"
        :key="item.id"
      >
        <img class="news-image" alt="" :src="item.attributes.banner_url">
        <h3>{{ item.attributes.title }}</h3>
        <p>{{ item.attributes.description }}</p>
        <router-link
          :to="{
            name: 'BlogPost',
            params: { slug: item.attributes.slug }
          }"
          exact
        >
          Ler mais
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { blogService } from '@/services/blog.service'

export default {
  name: 'News',

  data () {
    return {
      posts: []
    }
  },

  mounted () {
    this.getPosts()
  },

  methods: {
    async getPosts () {
      try {
        const pagination = {
          perPage: 3,
          page: 1
        }
        const posts = await blogService.listPosts(pagination)
        this.posts = posts.data.data.slice(0, 3)
      } catch (error) {
        console.log('Ocorreu um erro inesperado.')
      }
    }

  }
}
</script>

<style lang="scss" scoped>
  .news {
    padding: 50px 0 160px;

    .container {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      align-items: flex-start;

      &.container-news {
        max-width: 1200px;
        margin-top: 62px;
      }
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
      color: #2F2F33;
      font-weight: 300;
    }

    .view-more {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--maya-blue);
      font-weight: 500;
      font-size: 12px;
      gap: 4px;
      text-decoration: none;
      border: solid 1px var(--gainsboro);
      width: 112px;
      height: 37px;
      border-radius: 12px;
    }

    .box-news{
      max-width: 380px;
      width: 100%;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 30px;
        border-radius: 30px;
        height: 254px;
        object-fit: cover;
      }

      h3 {
        margin-bottom: 16px;
        font-weight: 500;
        line-height: 29px;
        font-size: var(--size-regular);
        color: var(--maya-blue);
        min-height: 65px;
      }

      p {
        font-weight: 300;
        line-height: 32px;
        font-size: var(--size-base);
        color: #757680;
        margin-bottom: 8px;
        min-height: 70px;
      }

      a {
        text-decoration: none;
        color: var(--maya-blue);
        font-weight: 700;
        font-size: 14px;

        &:hover,
        &:focus,
        &:active {
          color: var(--green-custom);
          animation: all 200ms ease-in-out;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0px 0 60px;

      .container {
        align-items: flex-start;
        flex-direction: column;

        &.container-news {
          margin-top: 32px;
        }
      }

      .box-news {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0px;
        }

        p {
          margin-bottom: 16px;
        }
      }
    }
  }
</style>
